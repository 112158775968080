/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterPersonalDetailsApiModel
 */
export interface RegisterPersonalDetailsApiModel {
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    customerType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    kvkNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    salutation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    infix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    mobilePhoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPersonalDetailsApiModel
     */
    customerCard?: string | null;
}

/**
 * Check if a given object implements the RegisterPersonalDetailsApiModel interface.
 */
export function instanceOfRegisterPersonalDetailsApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RegisterPersonalDetailsApiModelFromJSON(json: any): RegisterPersonalDetailsApiModel {
    return RegisterPersonalDetailsApiModelFromJSONTyped(json, false);
}

export function RegisterPersonalDetailsApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterPersonalDetailsApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerType': !exists(json, 'customerType') ? undefined : json['customerType'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'kvkNumber': !exists(json, 'kvkNumber') ? undefined : json['kvkNumber'],
        'salutation': !exists(json, 'salutation') ? undefined : json['salutation'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'infix': !exists(json, 'infix') ? undefined : json['infix'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'mobilePhoneNumber': !exists(json, 'mobilePhoneNumber') ? undefined : json['mobilePhoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'customerCard': !exists(json, 'customerCard') ? undefined : json['customerCard'],
    };
}

export function RegisterPersonalDetailsApiModelToJSON(value?: RegisterPersonalDetailsApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerType': value.customerType,
        'companyName': value.companyName,
        'kvkNumber': value.kvkNumber,
        'salutation': value.salutation,
        'firstName': value.firstName,
        'infix': value.infix,
        'lastName': value.lastName,
        'phoneNumber': value.phoneNumber,
        'mobilePhoneNumber': value.mobilePhoneNumber,
        'email': value.email,
        'customerCard': value.customerCard,
    };
}

