/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderLineApiModel } from './OrderLineApiModel';
import {
    OrderLineApiModelFromJSON,
    OrderLineApiModelFromJSONTyped,
    OrderLineApiModelToJSON,
} from './OrderLineApiModel';
import type { ShipmentApiModel } from './ShipmentApiModel';
import {
    ShipmentApiModelFromJSON,
    ShipmentApiModelFromJSONTyped,
    ShipmentApiModelToJSON,
} from './ShipmentApiModel';

/**
 * 
 * @export
 * @interface BasketApiModel
 */
export interface BasketApiModel {
    /**
     * 
     * @type {string}
     * @memberof BasketApiModel
     */
    id?: string | null;
    /**
     * 
     * @type {Array<OrderLineApiModel>}
     * @memberof BasketApiModel
     */
    orderLines?: Array<OrderLineApiModel> | null;
    /**
     * 
     * @type {Array<ShipmentApiModel>}
     * @memberof BasketApiModel
     */
    shipments?: Array<ShipmentApiModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasketApiModel
     */
    voucherCodes?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof BasketApiModel
     */
    subTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasketApiModel
     */
    discount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasketApiModel
     */
    vat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasketApiModel
     */
    shippingCosts?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasketApiModel
     */
    total?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasketApiModel
     */
    freeShippingThresholdExclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasketApiModel
     */
    freeShippingThresholdInclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasketApiModel
     */
    amountUntilFreeShippingExclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasketApiModel
     */
    amountUntilFreeShippingInclVat?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BasketApiModel
     */
    freeShipping?: boolean | null;
}

/**
 * Check if a given object implements the BasketApiModel interface.
 */
export function instanceOfBasketApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BasketApiModelFromJSON(json: any): BasketApiModel {
    return BasketApiModelFromJSONTyped(json, false);
}

export function BasketApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasketApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'orderLines': !exists(json, 'orderLines') ? undefined : (json['orderLines'] === null ? null : (json['orderLines'] as Array<any>).map(OrderLineApiModelFromJSON)),
        'shipments': !exists(json, 'shipments') ? undefined : (json['shipments'] === null ? null : (json['shipments'] as Array<any>).map(ShipmentApiModelFromJSON)),
        'voucherCodes': !exists(json, 'voucherCodes') ? undefined : json['voucherCodes'],
        'subTotal': !exists(json, 'subTotal') ? undefined : json['subTotal'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'shippingCosts': !exists(json, 'shippingCosts') ? undefined : json['shippingCosts'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'freeShippingThresholdExclVat': !exists(json, 'freeShippingThresholdExclVat') ? undefined : json['freeShippingThresholdExclVat'],
        'freeShippingThresholdInclVat': !exists(json, 'freeShippingThresholdInclVat') ? undefined : json['freeShippingThresholdInclVat'],
        'amountUntilFreeShippingExclVat': !exists(json, 'amountUntilFreeShippingExclVat') ? undefined : json['amountUntilFreeShippingExclVat'],
        'amountUntilFreeShippingInclVat': !exists(json, 'amountUntilFreeShippingInclVat') ? undefined : json['amountUntilFreeShippingInclVat'],
        'freeShipping': !exists(json, 'freeShipping') ? undefined : json['freeShipping'],
    };
}

export function BasketApiModelToJSON(value?: BasketApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'orderLines': value.orderLines === undefined ? undefined : (value.orderLines === null ? null : (value.orderLines as Array<any>).map(OrderLineApiModelToJSON)),
        'shipments': value.shipments === undefined ? undefined : (value.shipments === null ? null : (value.shipments as Array<any>).map(ShipmentApiModelToJSON)),
        'voucherCodes': value.voucherCodes,
        'subTotal': value.subTotal,
        'discount': value.discount,
        'vat': value.vat,
        'shippingCosts': value.shippingCosts,
        'total': value.total,
        'freeShippingThresholdExclVat': value.freeShippingThresholdExclVat,
        'freeShippingThresholdInclVat': value.freeShippingThresholdInclVat,
        'amountUntilFreeShippingExclVat': value.amountUntilFreeShippingExclVat,
        'amountUntilFreeShippingInclVat': value.amountUntilFreeShippingInclVat,
        'freeShipping': value.freeShipping,
    };
}

