/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveFavoriteLocationResponseModel
 */
export interface RemoveFavoriteLocationResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof RemoveFavoriteLocationResponseModel
     */
    useCookie?: boolean | null;
}

/**
 * Check if a given object implements the RemoveFavoriteLocationResponseModel interface.
 */
export function instanceOfRemoveFavoriteLocationResponseModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RemoveFavoriteLocationResponseModelFromJSON(json: any): RemoveFavoriteLocationResponseModel {
    return RemoveFavoriteLocationResponseModelFromJSONTyped(json, false);
}

export function RemoveFavoriteLocationResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveFavoriteLocationResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'useCookie': !exists(json, 'useCookie') ? undefined : json['useCookie'],
    };
}

export function RemoveFavoriteLocationResponseModelToJSON(value?: RemoveFavoriteLocationResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'useCookie': value.useCookie,
    };
}

