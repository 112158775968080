/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocationCardApiModel } from './LocationCardApiModel';
import {
    LocationCardApiModelFromJSON,
    LocationCardApiModelFromJSONTyped,
    LocationCardApiModelToJSON,
} from './LocationCardApiModel';

/**
 * 
 * @export
 * @interface GetFavoriteLocationResponseModel
 */
export interface GetFavoriteLocationResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof GetFavoriteLocationResponseModel
     */
    useCookie?: boolean | null;
    /**
     * 
     * @type {LocationCardApiModel}
     * @memberof GetFavoriteLocationResponseModel
     */
    location?: LocationCardApiModel;
}

/**
 * Check if a given object implements the GetFavoriteLocationResponseModel interface.
 */
export function instanceOfGetFavoriteLocationResponseModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetFavoriteLocationResponseModelFromJSON(json: any): GetFavoriteLocationResponseModel {
    return GetFavoriteLocationResponseModelFromJSONTyped(json, false);
}

export function GetFavoriteLocationResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFavoriteLocationResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'useCookie': !exists(json, 'useCookie') ? undefined : json['useCookie'],
        'location': !exists(json, 'location') ? undefined : LocationCardApiModelFromJSON(json['location']),
    };
}

export function GetFavoriteLocationResponseModelToJSON(value?: GetFavoriteLocationResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'useCookie': value.useCookie,
        'location': LocationCardApiModelToJSON(value.location),
    };
}

