/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResultApiModel
 */
export interface ResultApiModel {
    /**
     * 
     * @type {boolean}
     * @memberof ResultApiModel
     */
    success?: boolean | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ResultApiModel
     */
    validationErrors?: { [key: string]: string; } | null;
}

/**
 * Check if a given object implements the ResultApiModel interface.
 */
export function instanceOfResultApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResultApiModelFromJSON(json: any): ResultApiModel {
    return ResultApiModelFromJSONTyped(json, false);
}

export function ResultApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'validationErrors': !exists(json, 'validationErrors') ? undefined : json['validationErrors'],
    };
}

export function ResultApiModelToJSON(value?: ResultApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'validationErrors': value.validationErrors,
    };
}

