/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RegisterApiResponseModel,
  RegisterRequestModel,
} from '../models';
import {
    RegisterApiResponseModelFromJSON,
    RegisterApiResponseModelToJSON,
    RegisterRequestModelFromJSON,
    RegisterRequestModelToJSON,
} from '../models';

export interface RegisterPostRequest {
    registerRequestModel?: RegisterRequestModel;
}

/**
 * 
 */
export class RegisterApi extends runtime.BaseAPI {

    /**
     */
    async registerPostRaw(requestParameters: RegisterPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterApiResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterRequestModelToJSON(requestParameters.registerRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterApiResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async registerPost(requestParameters: RegisterPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterApiResponseModel> {
        const response = await this.registerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
