import { ScanResult, ScanningPermissionCallback, ScanningResultCallback } from './native';
import { SendInvokeMessageToDotNet, SendRawMessageToDotNet } from './native.interop';

const methodNames = {
  EchoMessage: 'EchoMessageFromJavascript',
  ToggleDebugMode: 'ToggleDebugModeFromJavascript',
  EnableScanning: 'EnableScanningFromJavascript',
  ScanningPermission: 'ScanningPermissionFromJavascript',
  LocationChanged: 'LocationChangedFromJavascript',
  EnableMaintenanceMode: 'EnableMaintenanceModeFromJavascript',
  ShareUrl: 'ShareUrlFromJavascript',
};

let scanningPermissionCallback: ScanningPermissionCallback;
let scanningResultCallback: ScanningResultCallback = defaultScanningResultCallback;
let pageLoading = false;

export function rawMessage() {
  SendRawMessageToDotNet('This is a raw message from JS!');
}

export function requestEchoMessage() {
  SendInvokeMessageToDotNet(methodNames.EchoMessage, 'Your message here');
}

export function toggleDebugMode() {
  SendInvokeMessageToDotNet(methodNames.ToggleDebugMode);
}

export function requestScanningPermission() {
  SendInvokeMessageToDotNet(methodNames.ScanningPermission);
}

export function enableScanning() {
  SendInvokeMessageToDotNet(methodNames.EnableScanning);
}

export function enableMaintenanceMode() {
  SendInvokeMessageToDotNet(methodNames.EnableMaintenanceMode);
}

export function setScanningPermissionCallback(callback: ScanningPermissionCallback) {
  scanningPermissionCallback = callback;
}

export function setScanningResultCallback(callback: ScanningResultCallback) {
  scanningResultCallback = callback;
}

export function setPageLoading(loading: boolean) {
  pageLoading = loading;
}

export function shareUrl(title: string, url: string | null, shareText: string | null) {
  SendInvokeMessageToDotNet(methodNames.ShareUrl, title, url, shareText);
}

function notifyLocationChanged() {
  SendInvokeMessageToDotNet(methodNames.LocationChanged);
}

export function initializeNative() {
  // Only execute this logic if it's requested by the client
  if (!process.client) {
    return;
  }

  // Methods invoked by native
  window.echoMessage = function (message: string) {
    return '[JS] ' + message;
  };

  window.scanningPermission = function (allowed: boolean) {
    if (scanningPermissionCallback) {
      scanningPermissionCallback(allowed);
    }
  };

  window.scanningResult = function (results: Array<ScanResult>) {
    if (scanningResultCallback) {
      scanningResultCallback(results);
    }
  };

  window.isPageLoading = function () {
    return pageLoading;
  };

  // Notify native about changes to the URL
  const originalPushState = history.pushState;
  const originalReplaceState = history.replaceState;

  history.pushState = function () {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    originalPushState.apply(history, arguments);

    notifyLocationChanged();
  };

  history.replaceState = function () {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    originalReplaceState.apply(history, arguments);

    notifyLocationChanged();
  };

  window.addEventListener('popstate', function () {
    notifyLocationChanged();
  });
}

function defaultScanningResultCallback(results: Array<ScanResult>) {
  alert(results.map((e) => `${e.Format}: ${e.Value}`).join('\r\n'));
}
